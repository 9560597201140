import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Container, Card, Segment } from 'semantic-ui-react'
import TextTruncate from 'react-text-truncate'
import { withTranslation } from 'react-i18next'
import Img from 'gatsby-image'

import { Link } from '../locales'
import { getFluidImage, getRawImage } from '../utils/images'
import { unescape, stripHtml } from '../utils/helpers'

import Layout from '../components/Layouts/PageLayout'
import PageHeader from '../components/Items/PageHeader'

const Countries = ({ data, t, i18n, pageContext }) => {
  const {
    allFirestoreCountry: { edges: countries },
    firestoreImage: { cloudinary, transformation, alt, title }
  } = data

  return (
    <>
      <Helmet>
        <title>{t('countries.header.title') + t('htmlTitleSuffix')}</title>

        <meta name='description' content={t('countries.header.subtitle')} />
        <meta name='image' content={getRawImage({ cloudinary })} />

        <meta property='og:title' content={t('countries.header.title')} />
        <meta
          property='og:description'
          content={t('countries.header.subtitle')}
        />
        <meta property='og:image' content={getRawImage({ cloudinary })} />

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:creator' content='Tischler Reisen' />
        <meta name='twitter:title' content={t('countries.header.title')} />
        <meta
          name='twitter:description'
          content={t('countries.header.subtitle')}
        />
        <meta name='twitter:image' content={getRawImage({ cloudinary })} />
      </Helmet>

      <Layout pageContext={pageContext}>
        <Img
          loading='lazy'
          fadeIn={false}
          fluid={getFluidImage({ cloudinary, transformation, ar: 2.5 })}
          alt={alt || title}
        />

        <PageHeader
          title={t('countries.header.title')}
          subtitle={t('countries.header.subtitle')}
        />

        <Segment vertical padded='very' basic>
          <Container>
            <Segment vertical padded='very' basic>
              <Card.Group itemsPerRow={3} doubling stackable>
                {countries.map(
                  ({ node: { id, title, alt, lead, slug, coverImage } }) => (
                    <Card key={id}>
                      <Link
                        to={`/countries/${slug}`}
                        lang={i18n.language}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <Img
                          loading='lazy'
                          fadeIn={false}
                          fluid={getFluidImage({
                            cloudinary: coverImage,
                            maxWidth: 300
                          })}
                          alt={alt || title}
                        />
                      </Link>

                      <Card.Content style={{ padding: '1em 0' }}>
                        <Card.Header>
                          <Link
                            to={`/countries/${slug}`}
                            lang={i18n.language}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {title}
                          </Link>
                        </Card.Header>
                        <Card.Description>
                          <TextTruncate
                            text={unescape(stripHtml(lead))}
                            line={5}
                            textTruncateChild={
                              <span>
                                <Link
                                  to={`/countries/${slug}`}
                                  lang={i18n.language}
                                >
                                  <i
                                    className='icon red ta-arrow-1-right'
                                    aria-label={title}
                                  />
                                </Link>
                              </span>
                            }
                          />
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  )
                )}
              </Card.Group>
            </Segment>
          </Container>
        </Segment>
      </Layout>
    </>
  )
}

export default withTranslation()(Countries)

export const pageQuery = graphql`
  query countriesQuery($lang: String!) {
    allFirestoreCountry(
      filter: { lang: { eq: $lang } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          lang
          title
          lead
          slug
          countryRegion
          countryRegionSlug
          coverImage
        }
      }
    }
    firestoreImage(page: { eq: "countryinfo" }) {
      cloudinary
      transformation
      title
      alt
    }
  }
`
